export default function IconcCoupons() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="27" viewBox="0 0 48 27" fill="none">
            <path
                id="Vector"
                d="M47 26.5H1C0.851381 26.5 0.718081 26.4464 0.627376 26.3647C0.538406 26.2847 0.5 26.1881 0.5 26.1V18.9C0.5 18.8119 0.538408 18.7153 0.627376 18.6353C0.718079 18.5536 0.851379 18.5 1 18.5C3.98342 18.5 6.5 16.3047 6.5 13.5C6.5 10.6953 3.98342 8.5 1 8.5C0.851384 8.5 0.718083 8.44639 0.627376 8.36475C0.538407 8.28468 0.5 8.18807 0.5 8.1V0.9C0.5 0.855236 0.509713 0.809412 0.530325 0.764627C0.551026 0.719647 0.583037 0.675155 0.627376 0.635251C0.671817 0.595254 0.72735 0.561102 0.791971 0.537012C0.856618 0.512912 0.927444 0.5 1 0.5H47C47.0726 0.5 47.1434 0.512912 47.208 0.537013C47.2726 0.5611 47.3282 0.595248 47.3726 0.635249L47.707 0.263694L47.3726 0.635255C47.417 0.675167 47.449 0.71966 47.4697 0.764627C47.4903 0.809397 47.5 0.85522 47.5 0.9V8.1C47.5 8.18807 47.4616 8.28467 47.3726 8.36475L47.7071 8.7364L47.3726 8.36475C47.2819 8.44639 47.1486 8.5 47 8.5C44.0166 8.5 41.5 10.6953 41.5 13.5C41.5 16.3047 44.0166 18.5 47 18.5C47.1486 18.5 47.2819 18.5536 47.3726 18.6352C47.4616 18.7153 47.5 18.8119 47.5 18.9V26.1C47.5 26.1881 47.4616 26.2847 47.3726 26.3647C47.2819 26.4464 47.1486 26.5 47 26.5Z"
                stroke="black"
            />
        </svg>
    );
}
